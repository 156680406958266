.source-validator {
	display: inline-block;
}

.source-validator:hover {
	cursor: help;
	background-color: var(--primary-pale) !important;
}

.source-validator-page-container {
	border: 1px solid var(--secondary-dark);
}

.source-validator-dialog-content {
	font-size: 20px;
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: left;
	gap: 10px;
}

.source-validator-line-item {
	border: 1px solid var(--secondary);
	border-radius: 3px;
	display: flex;
}

.source-validator-line-item .heading {
	background-color: #eee;
	font-weight: 500;
	padding: 5px;
	border-right: 1px solid var(--secondary);
}

.source-validator-line-item .content {
	padding: 5px;
}

.source-validator-file {
	cursor: pointer;
}
