.notfound-icon-container {
	color: var(--secondary-dark);
	margin-top: 100px;
}

.notfound-text-container {
	font-size: 24px;
}

.notfound-text-container h1 {
	color: var(--secondary-dark);
}
