.login-container {
	margin: auto;
	display: flex;
	justify-content: center;
	height: 100vh;
	background-image: linear-gradient(var(--primary), var(--primary-dark));
	background-attachment: fixed;
}

.login-form-container {
	margin-top: 200px;
	width: 400px;
	margin-bottom: auto;
	background-color: #ffffff;
	padding: 64px;
	border-radius: 10px;
	border: 1px solid var(--primary-light);
}

.login-form {
	display: flex;
	flex-direction: column;
}

.logo-container {
	align-self: center;
}

.mfa-alert {
	line-height: 24px;
}
