.navbar-link-button {
	color: var(--primary-text) !important;
	padding: none !important;
}

.navbar-logo {
	padding-bottom: 6px;
}

.app-toolbar {
	display: flex;
	align-items: center !important;
}
