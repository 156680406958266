.dashboard-table-header {
	background-color: var(--secondary) !important;
}

.memo-format-icon {
	border: 1px solid var(--secondary);
	padding: 0.1rem;
	border-radius: 2px;
	margin-left: 0.3rem;
	width: 14px;
	text-align: center;
	display: inline-block;
}

.memo-format-icon.docx {
	color: #2b579a;
	border: 1px solid #2b579a;
}

.memo-format-icon.pptx {
	color: #d24726;
	border: 1px solid #d24726;
}

.memo-format-icon.pptx-hybrid {
	/* Gradient from pptx to docx */
	background: linear-gradient(0deg, #d24726 50%, #2b579a 50%);
	background-clip: text;
	color: transparent;
	border: 1px solid #d24726;
}
