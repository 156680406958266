.chat-message {
	padding: var(--m);
	border-radius: 5px;
	font-size: 18px;
	white-space: normal;
	display: flex;
	flex-direction: column;
}

.chat-message.sidekick {
	background-color: var(--primary-pale);
	border: 1px solid var(--primary);
	margin-right: auto;
}

.chat-message.user {
	background-color: #eee;
	border: 1px solid var(--secondary-pale);
	margin-left: auto;
}

.sidekick-logo-container {
	color: var(--primary);
	font-weight: 600;
	font-size: 2.2rem;
	display: flex;
	align-items: center;
	gap: 5px;
	justify-content: center;
	padding: var(--s) 0;
}

.sidekick-container {
	margin: auto;
	max-width: 1200px;
}

.sidekick-chat-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	position: relative;
	padding: var(--m);
	min-width: 1200px;
}

.sidekick-placeholder-container {
	height: 85dvh;
	display: flex;
}

.sidekick-placeholder-logo {
	font-size: 9rem;
	color: var(--primary);
}

.sidekick-placeholder-content {
	color: var(--secondary-dark);
	font-size: 3rem;
	font-weight: 500;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	gap: 40px;
}

.highlight-deal-selection {
	background-color: var(--primary-pale);
}

.sidekick-chatbox-container {
	position: fixed;
	bottom: 20px;
	min-width: 1200px;
}

.sidekick-chatbox-content-spacer {
	height: 100px;
}
