.upload-files-container {
	border: 2px solid transparent;
	width: auto;
}

.upload-files-container.show-background {
	background-color: #fff;
	border-radius: 4px;
}

.upload-files-container.dragging {
	border: 2px dashed #ccc;
	transition: 0.5s ease-out;
	background-color: #eee;
	border-radius: 5px;
}

.deal-file {
	user-select: none;
}

.deal-file:hover {
	background-color: #ddd;
	transition: 0.5s ease-out;
	cursor: pointer;
}

.deal-file.uploading:hover {
	cursor: default;
}

.upload-files-container a {
	text-decoration: none;
	color: black !important;
}

.deal-files-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.deal-file.deleting {
	color: red;
	background-color: rgb(255, 226, 226);
	border: 1px solid red;
}

.deal-file.locked {
	background-color: rgb(255, 233, 193);
}

.deal-files-header {
	width: auto;
	padding: var(--s) var(--s) 0 var(--s);
}

.file-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
}

.file-icon-text {
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	text-align: center;
	font-size: 10px;
	border: 1px solid #333;
	border-radius: 4px;
	width: 22px;
	font-weight: 500;
}
