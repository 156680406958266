.section-block {
	padding: var(--m) var(--l);
	min-width: 300px;
	width: auto;
	gap: 10px;
	scroll-margin-top: 110px;
}

.section-block .source-validator {
	background-color: #eee;
}

.section-type-select {
	min-width: 300px;
}

.section-type-select-container {
	align-items: flex-end;
}

.section-block-number {
	font-size: 1.3em;
	font-weight: 500;
	display: flex;
	align-items: center;
}

.section-content-container {
	width: auto;
}

.section-content-container:empty {
	display: none;
}

.section-content-body {
	padding: var(--m);
	white-space: normal;
	text-align: justify;
	font-size: 1.1em;
}

.section-content-body:empty {
	display: none;
}

.generate-button {
	flex-grow: 0;
}

.delete-section-button:hover {
	background-color: #ffc3c3 !important;
	color: #ff0000 !important;
}

.section-content-body img {
	margin-left: auto;
	margin-right: auto;
	display: block;
}
.section-editing {
	position: relative;
}

.section-editing-loading-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	backdrop-filter: blur(1px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.section-editing-loading-content {
	background-color: #ffffff;
	padding: var(--xl);
	border-radius: var(--s);
	border: 1px solid var(--secondary);
	display: flex;
	flex-direction: column;
	gap: 15px;
	align-items: center;
}

.custom-prompt-input {
	width: 100%;
}

.margin-veritcal-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.margin-horizontal-auto {
	margin-left: auto;
	margin-right: auto;
}

.margin-auto {
	margin: auto;
}

.section-chat-container {
	background-color: var(--primary-pale);
	padding: var(--m);
	border-radius: var(--s);
	border: 1px solid rgba(0, 0, 0, 0.12);
}

.section-chat-input {
	background-color: #fff;
}

.section-chat-button {
	background-color: #fff !important;
}

.content-history-item-prefix {
	background-color: #ddd;
	border-radius: 4px;
	padding: 4px 6px;
}

.content-history-disabled-warning-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	backdrop-filter: blur(3px);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: not-allowed;
	z-index: 3;
}

.content-history-disabled-warning {
	color: var(--error);
	font-weight: 500;
	background-color: #fff;
	border-radius: var(--s);
	padding: var(--s) var(--m);
	border: 1px solid var(--error);
}

.custom-prompt-input-container {
	padding: var(--s) 0px;
}

.chart-download-button {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	border-radius: 3px;
	padding: 5px 10px;

	position: absolute;
	bottom: 10px;
	right: 10px;

	&:hover {
		background-color: rgba(33, 115, 70, 0.7);
		cursor: pointer;
	}
}

.chart-image {
	position: relative;
}
