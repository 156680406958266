.document-toolbar {
	border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
	position: sticky;
	top: 56px;
	background-color: white;
	border-left: 1px rgba(0, 0, 0, 0.12) solid;
	border-right: 1px rgba(0, 0, 0, 0.12) solid;
	z-index: 1050;
	height: 40px;
	scroll-padding-top: 0px !important;
}

.question-list-container {
	margin: auto;
	background-color: #fff;
	width: 1200px;
	border-left: 1px rgba(0, 0, 0, 0.12) solid;
	border-right: 1px rgba(0, 0, 0, 0.12) solid;
}

.question-list-category-container {
	display: flex;
	gap: 10px;
	font-weight: 500;
	font-size: 24px;
	align-items: center;
}

.question-list-section {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: var(--m);
	font-size: 1.1em;
	scroll-margin-top: 110px;
}

.question-list-questions {
	font-size: 18px;
}

.question-list-questions li {
	margin-top: 10px;
}

.question-list-creator-container {
	margin: auto;
	height: 70dvh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.question-list-creator-form {
	min-width: 650px;
	background-color: #fff;
}

.question-list-creator-form-title {
	display: flex;
	justify-content: center;
	margin-top: auto;
	background: var(--secondary-dark);
	color: #fff;
	padding: var(--m) 0;
	font-size: 2rem;
	font-weight: 500;

	/* Only top border with border radius */
	border-top-left-radius: var(--s);
	border-top-right-radius: var(--s);
}

.question-list-creator-form-input {
	padding: var(--m);
}

.generate-question-list-button-container {
	padding: 0px 0px 15px 0px;
	display: flex;
	justify-content: center;
}

.question-skeleton {
	padding-left: 20px;
}

.question-number-skeleton {
	width: 1.5em;
	color: rgba(0, 0, 0, 0.4);
}
