.deal-creator-container {
	height: 70dvh;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.new-deal-form {
	background-color: #fff;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-width: 500px;
	border-radius: var(--s);
}

.new-deal-header {
	margin-top: auto;
	background: var(--secondary-dark);
	color: #fff;
	padding: var(--m) 0;
	font-size: 2rem;
	font-weight: 500;

	display: flex;
	justify-content: center;

	/* Only top border with border radius */
	border-top-left-radius: var(--s);
	border-top-right-radius: var(--s);
}

.new-deal-body {
	display: flex;
	flex-direction: column;
	padding: var(--l);
	gap: 20px;
}

.deal-dashboard-container {
	margin: auto;
	margin-top: var(--l);
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1200px;
}
