.add-section-button {
	position: sticky !important;
	bottom: 10px;
	background-color: #eee !important;
	width: 200px;
	align-self: center;
}

.build-memo-container {
	width: 1200px;
	margin: auto;
	background-color: #fff;
	border-left: 1px solid rgba(0, 0, 0, 0.12);
	border-right: 1px solid rgba(0, 0, 0, 0.12);
}
