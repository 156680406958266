.slide {
	width: 500px;
	height: 375px;
	border: 1px solid black;
	padding: var(--l);
	overflow: auto;
}

.slide-title {
	border-bottom: 1px solid var(--secondary);
	padding-bottom: var(--s);
}

.slide-bullets li {
	margin: var(--m) 0;
	font-size: 1.2rem;
	text-align: left;
}

.slide-viewer {
	display: flex;
	justify-content: center;
}

.slide-changer {
	flex-grow: 1;
	align-content: center;
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
	user-select: none;
	color: #ccc;
}

.slide-changer.enabled {
	visibility: visible;
}

.slide-changer.enabled:hover {
	cursor: pointer;
	background-color: #f5f5f5;
	color: #000;
}

.slide-changer-label {
	font-size: 3rem;
}

.slide-tracker {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: var(--s) 0;
}
