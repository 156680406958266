.home-container {
	padding: 42px 0px;
}

.link-tile {
	height: 160px;
	border-radius: 6px;
	padding: 20px 24px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	color: white;
	opacity: 1;
	transition: opacity 0.2s ease-in-out;
}

.link-tile:hover {
	opacity: 0.8;
}

.link-tile h3 {
	color: white;
}

.link-tile-description {
	margin: 2px 0px;
}

.link-tile-bubble {
	background: rgba(255, 255, 255, 0.8);
	border-radius: 3px;
	padding: 2px 4px;
	color: var(--text-color);
	font-weight: 600;
}

.desaturate {
	filter: grayscale(100%);
}
