.title-tile {
	width: auto;
	height: 100px;
	color: #fff;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	color: white;
	padding: 20px 24px;
	flex: 1;
}

.title-tile h1 {
	color: white;
}

.memo-tile-bg {
	background: linear-gradient(210deg, #82a4e4, #245fcc 100%);
	--text-color: #245fcc;
}

.memo-docx-tile-bg {
	background: linear-gradient(210deg, #82a4e4, #245fcc 100%);
	--text-color: #245fcc;
}

.memo-pptx-tile-bg {
	background: linear-gradient(210deg, #d69f72, #e07116 100%);
	--text-color: #245fcc;
}

.memo-pptx-hybrid-tile-bg {
	background: linear-gradient(210deg, #82a4e4, #e07116 100%);
	--text-color: #245fcc;
}

.model-tile-bg {
	background: linear-gradient(210deg, #8dcaa5, #05a040 100%);
	--text-color: #05a040;
}

.question-tile-bg {
	background: linear-gradient(210deg, #d672c9, #a016e0 100%);
	--text-color: #a016e0;
}

.deal-tile-bg {
	background: linear-gradient(210deg, #333333, #85817f 100%);
	--text-color: #fff;
}

.sidekick-tile-bg {
	background: linear-gradient(210deg, var(--primary-pale), var(--primary) 100%);
	--text-color: var(--primary);
}
