@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

:root {
	--primary: #00e575;
	--primary-light: #66ffa5;
	--primary-pale: #d5ffe6;
	--primary-dark: #30b380;
	--primary-text: #fafafa;
	--secondary: #ced7db;
	--secondary-light: #eee;
	--secondary-pale: #ccc;
	--secondary-dark: #5d6061;
	--secondary-text: #000000;
	--error: #d32f2f;
	--warning: #ed6c02;

	--xs: 4px;
	--s: 8px;
	--m: 16px;
	--l: 24px;
	--xl: 32px;

	scrollbar-gutter: stable;
}

body {
	margin: 0;
	padding: 0 !important;
	font-family: "Barlow", sans-serif !important;
	font-weight: 300;
}

body p {
	margin: 0.5em 0px;
}

body a {
	text-decoration: none;
	color: var(--primary);
}

body h1,
h2,
h3 {
	margin: 0;
	color: #202123;
	font-weight: 500;
}

.u-flex {
	display: flex;
}

.u-pointer {
	cursor: pointer;
}

.u-flex-column {
	display: flex;
	flex-direction: column;
}

.u-justify-center {
	justify-content: center;
}

.u-justify-end {
	justify-content: flex-end;
}

.u-align-center {
	align-items: center;
}

.u-gap-5 {
	gap: 5px;
}

.u-gap-10 {
	gap: 10px;
}

.u-gap-15 {
	gap: 15px;
}

.u-gap-20 {
	gap: 20px;
}

.u-gap-25 {
	gap: 25px;
}

.u-gap-30 {
	gap: 30px;
}

.u-gap-35 {
	gap: 35px;
}

.u-gap-40 {
	gap: 40px;
}

.logo-white {
	color: var(--primary-text);
	font-size: 1.5em;
	font-weight: 500;
}

.logo-green {
	color: var(--primary);
	font-size: 1.5em;
	font-weight: 500;
}

.logo-large {
	font-size: 4em;
	font-weight: 500;
}

.u-flex-grow-1 {
	flex-grow: 1;
}

.u-flex-grow-0 {
	flex-grow: 0;
}

.u-flex-wrap-row {
	flex-wrap: wrap;
}

.u-align-self-center {
	align-self: center;
}

.u-align-self-end {
	align-self: flex-end;
}

.u-align-self-start {
	align-self: flex-start;
}

.u-primary-gradient-background {
	/* Background Gradient from primary to primary dark */
	background: linear-gradient(
		18deg,
		var(--primary) 0%,
		var(--primary-dark) 100%
	);
}

.u-space-between {
	justify-content: space-between;
}

.u-flex-1 {
	flex: 1;
}

.u-width-auto {
	width: auto;
}

.u-width-100 {
	width: 100%;
}

.u-padding-s {
	padding: var(--s);
}

.u-padding-m {
	padding: var(--m);
}

.u-padding-l {
	padding: var(--l);
}

.u-padding-xl {
	padding: var(--xl);
}

.u-padding-s-l {
	padding: var(--s) var(--l);
}

.u-padding-m-l {
	padding: var(--m) var(--l);
}

.u-space-between {
	justify-content: space-between;
}

.u-absolute-align-center {
	top: 50%;
	transform: translateY(-50%);
}

.spacer-100 {
	height: 100px;
}

.MuiListSubheader-root {
	font-weight: 600 !important;
	font-size: 1em !important;
}
