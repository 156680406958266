.app-container {
	max-width: 1200px;
	margin: auto;
	scroll-padding-top: 200px;
}

body::-webkit-scrollbar {
	width: 10px !important;
}

body::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.4) !important;
	border-radius: 10rem !important;
	border: 1px solid #fff !important;
}

body::-webkit-scrollbar-track-piece:start {
	background: transparent !important;
}

body::-webkit-scrollbar-track-piece:end {
	background: transparent !important;
}
