.memo-title {
	font-size: 48px !important;
}

.document-toolbar {
	border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
	top: 56px;
	background-color: white;
	border-left: 1px rgba(0, 0, 0, 0.12) solid;
	border-right: 1px rgba(0, 0, 0, 0.12) solid;
	z-index: 1050;
	height: 40px;
	scroll-padding-top: 0px !important;
}
