.profile-name-banner {
	font-size: 2.5rem;
}

.profile-field {
	font-size: 1.1rem;
}

.profile-section {
	width: auto;
}

.profile-container {
	margin: auto;
	background-color: #fff;
	padding: var(--l);
	border: 1px solid rgb(192, 192, 192);
	border-radius: 5px;
	margin-top: var(--l);
}
