body {
	/* Pale grey to even lighter grey gradient background */
	background-color: #f5f5f5;
}

.memo-container {
	margin: auto;
}

.memo-selector-container {
	height: 70dvh;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.memo-selector-form {
	background-color: #fff;
	margin: auto;
}

.build-memo-container {
	max-width: 1200px;
	margin: auto;
}

.memo-creation-header {
	margin-top: auto;
	background: var(--secondary-dark);
	color: #fff;
	padding: var(--m) 0;
	font-size: 2rem;
	font-weight: 500;

	/* Only top border with border radius */
	border-top-left-radius: var(--s);
	border-top-right-radius: var(--s);
}

.memo-creation-body {
	padding: var(--l);
}

.memo-format-chooser-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: var(--m) 0;
}

.memo-format-chooser {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: var(--m) 0;
	gap: var(--m);
}

.deal-selector {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: var(--m) 0;
	gap: var(--m);
}

.memo-format-tile {
	padding: var(--m);
	border-radius: var(--s);
	border: 1px solid var(--secondary);
	font-size: 1.2rem;
	font-weight: 500;
	text-align: center;
}

.memo-format-tile:hover {
	cursor: pointer;
}

.memo-format-tile.docx {
	color: #2b579a;
}

.memo-format-tile.pptx {
	color: #d24726;
}

.memo-format-tile.pptx-hybrid {
	color: #d24726;
}

.memo-format-tile.docx:hover {
	background-color: #bcd7ff;
}

.memo-format-tile.pptx:hover {
	background-color: #ffbc9a;
}

.memo-format-tile.pptx-hybrid:hover {
	/* Gradient between docx and pptx */
	background: linear-gradient(to right, #bcd7ff 0%, #ffbc9a 100%);
}

.memo-format-tile.docx.selected {
	background-color: #bcd7ff;
}

.memo-format-tile.pptx.selected {
	background-color: #ffbc9a;
}

.memo-format-tile.pptx-hybrid.selected {
	/* Gradient between docx and pptx */
	background: linear-gradient(to right, #bcd7ff 0%, #ffbc9a 100%);
}
