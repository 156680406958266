.drawer-section-header {
	display: flex;
	align-items: center;
	padding: 10px var(--s);
}

.drawer-section-row {
	padding: 4px;
}

.left-menu {
	position: fixed;
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100% - 113px);
	background-color: #fff;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
	width: 340px;
	display: flex;
	flex-direction: column;
	z-index: 1200;
}

.left-menu-spacer {
	width: 340px;
}

.generate-memo-button-container {
	padding: 6px;
	display: flex;
	justify-content: center;
}

/* Drawer menu styling */
.left-menu::-webkit-scrollbar {
	width: 7px !important;
}

.left-menu::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.4) !important;
	border-radius: 10rem !important;
	border: 1px solid #fff !important;
}

.left-menu::-webkit-scrollbar-track-piece:start {
	background: transparent !important;
}

.MuiDrawer-paper::-webkit-scrollbar-track-piece:end {
	background: transparent !important;
}
